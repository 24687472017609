import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import createStorageMiddleware, {
  getStorageState
} from 'redux-simple-storage-middleware'
import initSubscriber from 'redux-subscriber'
import reducers from './rootReducer'

const composeEnhancers =
  process.env.NODE_ENV === 'production'
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sessionStorageMiddleware = createStorageMiddleware({
  key: 'skd-session-storage',
  type: 'sessionStorage'
})

const storageState = getStorageState({
  key: 'skd-session-storage',
  type: 'sessionStorage'
})

const store = createStore(
  reducers,
  storageState,
  composeEnhancers(applyMiddleware(thunk, sessionStorageMiddleware))
)

const subscribe = initSubscriber(store)
export { store, subscribe }
