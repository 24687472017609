import ax from '../../common/helpers/request'
import { addUserNotification } from '../home/action'
import { switchLayoutLoading } from '../layout/action'

export const FETCH_SALE = 'FETCH_SALE'
export const FETCH_SALE_CHECK = 'FETCH_SALE_CHECK'
export const FETCH_TRANSACTION_ID = 'FETCH_TRANSACTION_ID'

export const fetchSale = data => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      const { res } = await ax.post(`/api/sales`, data)
      dispatch({ type: FETCH_SALE, payload: res })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const fetchSaleCheck = dto => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      const { data } = await ax.post(`/api/sales/check`, dto)
      dispatch({ type: FETCH_SALE_CHECK, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const fetchTransactionId = () => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      const { data } = await ax.get(`/api/sales/transaction/id`)
      dispatch({ type: FETCH_TRANSACTION_ID, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}
