import { makeStyles } from '@material-ui/core/styles'
import { appColors } from '../../../../common/constants'

const useStyles = makeStyles(theme => ({
  accountSidebar: {
    width: 320,
    height: '100vh',
    position: 'absolute',
    zIndex: 10000,
    left: 0,
    top: 0,
    backgroundColor: appColors.white,
    borderRadius: '0 32px 32px 0',
    padding: '40px 24px',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box'
  },
  accountSidebarHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  sidebarTitle: {
    fontSize: '20px',
    margin: 0
  },
  sidebarCloseIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  },

  avatarBlock: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    width: 38,
    height: 38,
    borderRadius: '50%',
    backgroundColor: '#4781D8',
    color: appColors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20
  },
  avatarDescription: {},
  avatarName: {
    fontSize: 14,
    fontWeight: 600
  },
  avatarStoreName: {
    fontSize: 12,
    color: appColors.gray1
  },
  accountSidebarMenuButton: {
    height: 44,
    marginTop: 20,
    borderRadius: 8,
    fontSize: 14,
    fontWeight: 600,
    color: appColors.dark,
    backgroundColor: appColors.light,
    textTransform: 'none'
  },

  accountSidebarExitButton: {
    marginTop: 'auto',
    color: appColors.red
  },
  warningLogoutBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  warningLogoutMessage: {
    marginTop: 13,
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'center'
  }
}))

export default useStyles
