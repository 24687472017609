import ax from '../../common/helpers/request'
import history from '../../common/helpers/routingHistory'
import { addUserNotification } from '../home/action'
import { switchLayoutLoading } from '../layout/action'
import { SET_INITIAL_STATE_NEW_IMPORT } from './constants'

export const FETCH_IMPORTS = 'FETCH_IMPORTS'

export const fetchImports = ({ page, size, searchText }) => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      const { data } = await ax.get(
        `/api/products/imports?page=${page}&size=${size}&searchText=${searchText}`
      )

      dispatch({ type: FETCH_IMPORTS, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const importSubmit = dto => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      await ax.post('/api/products/upload', dto)
      dispatch({ type: SET_INITIAL_STATE_NEW_IMPORT })
      dispatch(addUserNotification('Новый импорт успешно создан!', 'success'))
      history.push('/goods/imports')
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}
