import { CLEAR_STORE } from '../home/constants'
import { FETCH_NEWS } from './actions'

const initialState = {
  news: { content: [], totalElements: 0 }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NEWS:
      return { ...state, news: action.payload }

    case CLEAR_STORE:
      return { ...initialState }

    default:
      return state
  }
}

export default reducer
