import ax from '../../common/helpers/request'
import { addUserNotification } from '../home/action'
import { switchLayoutLoading } from '../layout/action'

export const FETCH_PROVIDERS = 'FETCH_PROVIDERS'

export const fetchProviders = (page, size, phoneNumber = '', name = '') => {
  phoneNumber = phoneNumber.replace(/[^0-9]/g, '').slice(1)

  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      const { data } = await ax.get(
        `/api/providers?page=${page}&size=${size}&phoneNumber=${phoneNumber}&name=${name}`
      )
      dispatch({ type: FETCH_PROVIDERS, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const updateProvider = ({ id, name, phoneNumber }) => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      await ax.put(`/api/providers/${id}`, {
        name,
        phoneNumber
      })

      dispatch(
        addUserNotification(`Поставщик ${id} успешно обновлен`, 'success')
      )

      return true
    } catch (error) {
      dispatch(addUserNotification(error))

      return false
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}
