import { CLEAR_STORE } from '../home/constants'
import { SET_PRODUCTS } from './actions'

const initialState = {
  products: { content: [], totalElements: 0 }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return { ...state, products: action.payload }

    case CLEAR_STORE:
      return { ...initialState }

    default:
      return state
  }
}

export default reducer
