import { CLEAR_STORE } from '../home/constants'
import { FETCH_IMPORTS } from './actions'
import { SET_IMPORT_DATA, SET_INITIAL_STATE_NEW_IMPORT } from './constants'

const initialState = {
  imports: { content: [], totalElements: 0 },
  newImportData: {
    name: '',
    store: '',
    file: null
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_IMPORTS:
      return { ...state, imports: action.payload }

    case SET_IMPORT_DATA:
      const { name, value } = action.payload
      return {
        ...state,
        newImportData: { ...state.newImportData, [name]: value }
      }

    case SET_INITIAL_STATE_NEW_IMPORT:
      return initialState

    case CLEAR_STORE:
      return { ...initialState }

    default:
      return state
  }
}

export default reducer
