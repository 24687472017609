import { USER_ROLES } from '../common/constants'

export const routes = [
  {
    path: ['/', '/login'],
    exact: true,
    component: 'home',
    allowedRoles: []
  },
  {
    path: '/not-allowed',
    exact: true,
    component: 'notAllowed',
    allowedRoles: []
  },
  {
    path: '/settings/stores',
    exact: true,
    component: 'store',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.PICKER]
  },
  {
    path: '/marketing/news',
    exact: true,
    component: 'news',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.PICKER]
  },
  {
    path: '/marketing/registration_orders',
    exact: true,
    component: 'registrationOrders',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.PICKER]
  },
  {
    path: '/sells/orders',
    exact: true,
    component: 'orders',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.CASHIER, USER_ROLES.PICKER]
  },
  {
    path: '/sells/orders/:id',
    exact: true,
    component: 'order',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.PICKER]
  },
  {
    path: '/create_news',
    exact: true,
    component: 'createNews',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.PICKER]
  },
  {
    path: '/clients/all_clients',
    exact: true,
    component: 'clients',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.PICKER]
  },
  {
    path: '/clients/all_clients/:id',
    exact: true,
    component: 'createClient',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.PICKER]
  },
  {
    path: '/sales/cash_register',
    exact: true,
    component: 'cashRegister',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.CASHIER, USER_ROLES.PICKER]
  },
  {
    path: '/sales/orders',
    exact: true,
    component: 'orders',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.PICKER]
  },
  {
    path: '/sales/all_sales',
    exact: true,
    component: 'allSales',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.CASHIER, USER_ROLES.PICKER]
  },
  {
    path: '/create_store',
    exact: true,
    component: 'createStore',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.PICKER]
  },
  {
    path: '/edit_store/:id',
    exact: true,
    component: 'editStore',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.PICKER]
  },
  {
    path: '/create_client',
    exact: true,
    component: 'createClient',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.PICKER]
  },
  {
    path: '/edit_product/:id',
    exact: true,
    component: 'editProduct',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.PICKER]
  },
  {
    path: '/goods/catalog',
    exact: true,
    component: 'catalog',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.CASHIER, USER_ROLES.PICKER]
  },
  {
    path: '/goods/imports',
    exact: true,
    component: 'imports',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.CASHIER, USER_ROLES.PICKER]
  },
  {
    path: '/goods/transfers',
    exact: true,
    component: 'transfers',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.CASHIER, USER_ROLES.PICKER]
  },
  {
    path: '/new_transfer',
    exact: true,
    component: 'newTransfer',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.PICKER]
  },
  {
    path: '/goods/providers',
    exact: true,
    component: 'providers',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.CASHIER, USER_ROLES.PICKER]
  },
  {
    path: '/create_product',
    exact: true,
    component: 'createProduct',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.PICKER]
  },
  {
    path: '/create_supplement',
    exact: true,
    component: 'createProduct/components/createSupplement',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.PICKER]
  },
  {
    path: '/edit_supplement/:id',
    exact: true,
    component: 'editProduct/components/editSupplement',
    allowedRoles: [USER_ROLES.ADMIN, USER_ROLES.PICKER]
  },
  {
    path: '/finances',
    exact: true,
    component: 'finances',
    allowedRoles: [USER_ROLES.ADMIN]
  }
]

export default routes
