export const LOGIN_LOADING = `LOGIN_LOADING`
export const GET_FINGERPRINT = `GET_FINGERPRINT`
export const SET_USER_CREDENTIALS = `SET_USER_CREDENTIALS`
export const USER_LOGOUT_ACTION = `USER_LOGOUT_ACTION`
export const ADD_USER_NOTIFICATION = 'ADD_USER_NOTIFICATION'
export const DISABLE_USER_NOTIFICATION = 'DISABLE_USER_NOTIFICATION'
export const DISABLE_ALL_NOTIFICATION = 'DISABLE_ALL_NOTIFICATION'
export const SET_AUTHORIZED = 'SET_AUTHORIZED'
export const CLEAR_STORE = 'CLEAR_STORE'

export const SET_LOGIN_TIME = 'SET_LOGIN_TIME'
