import { CLEAR_STORE } from '../home/constants'
import {
  GET_TASK_MANAGER_DATA_SUCCEEDED,
  CHANGE_USER_STATE_STARTED,
  CHANGE_USER_STATE_SUCCEEDED,
  CHANGE_USER_STATE_FAILED,
  GET_USER_STATE_LIST_STARTED,
  GET_USER_STATE_LIST_FAILED,
  GET_USER_STATE_LIST_SUCCEEDED,
  GET_USER_STATE_STARTED,
  GET_USER_STATE_SUCCEEDED,
  GET_USER_STATE_FAILED,
  SHOW_SUB_STATUS,
  SET_NEW_TASKS_NOTIFICATION_INFO,
  FETCH_NOTIF_NEW_TASKS_STARTED,
  FETCH_NOTIF_NEW_TASKS_SUCCEEDED,
  FETCH_NOTIF_NEW_TASKS_FAILED,
  READ_NEW_TASK_NOTIF_STARTED,
  READ_NEW_TASK_NOTIF_SUCCEEDED,
  READ_NEW_TASK_NOTIF_FAILED,
  SHOW_NEW_TASK_NOTIF,
  CLOSE_NEW_TASK_NOTIF,
  LAYOUT_LOADING
} from './constants'

const initState = {
  userStateList: [],
  currentSubStatuses: [],
  userActualState: { workState: '', workingMode: 'DEFAULT_WHITE_THEME' },
  taskManagerData: {},
  loading: false,
  error: null,
  newTasksNotificationInfo: {},
  newTasksNotifList: { content: [], totalElements: 0 },
  newTaskNotif: null
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_USER_STATE_STARTED:
      return { ...state, loading: true }
    case GET_USER_STATE_SUCCEEDED:
      let newSubStatuses = []
      if (action.payload.workState) {
        newSubStatuses = state.userStateList.find(
          status => status.code === action.payload.workState
        ).workingRefModes
      }

      return {
        ...state,
        loading: false,
        userActualState: {
          workState:
            action.payload.workState === null ? '' : action.payload.workState,
          workingMode:
            action.payload.workingMode === null
              ? ''
              : action.payload.workingMode
        },
        currentSubStatuses: newSubStatuses
      }
    case GET_USER_STATE_FAILED:
      return { ...state, loading: false, error: action.payload }

    case GET_USER_STATE_LIST_STARTED:
      return { ...state, loading: true }
    case GET_USER_STATE_LIST_SUCCEEDED:
      return { ...state, loading: false, userStateList: action.payload }
    case GET_USER_STATE_LIST_FAILED:
      return { ...state, loading: false, error: action.payload }

    case CHANGE_USER_STATE_STARTED:
      return { ...state, loading: true }
    case CHANGE_USER_STATE_SUCCEEDED:
      const subStatuses = state.userStateList.find(
        status => status.code === action.payload.workState
      ).workingRefModes

      return {
        ...state,
        loading: false,
        userActualState: {
          ...action.payload,
          workingMode:
            action.payload.workingMode === null
              ? ''
              : action.payload.workingMode
        },
        currentSubStatuses: subStatuses
      }

    case CHANGE_USER_STATE_FAILED:
      return { ...state, loading: false, error: action.payload }

    case SHOW_SUB_STATUS:
      return {
        ...state,
        showSubStatus: true,
        currentSubStatuses: action.subStatuses,
        userActualState: {
          ...state.userActualState,
          workState: action.status
        }
      }

    case GET_TASK_MANAGER_DATA_SUCCEEDED:
      return { ...state, taskManagerData: action.payload }

    case SET_NEW_TASKS_NOTIFICATION_INFO:
      return { ...state, newTasksNotificationInfo: action.payload }

    case FETCH_NOTIF_NEW_TASKS_STARTED:
      return { ...state, loading: true }
    case FETCH_NOTIF_NEW_TASKS_SUCCEEDED:
      return { ...state, loading: false, newTasksNotifList: action.payload }
    case FETCH_NOTIF_NEW_TASKS_FAILED:
      return { ...state, loading: false }

    case READ_NEW_TASK_NOTIF_STARTED:
      return { ...state, loading: true }
    case READ_NEW_TASK_NOTIF_SUCCEEDED:
      const newTasksContent = state.newTasksNotifList.content.map(obj => {
        return obj.id === action.payload ? { ...obj, read: true } : obj
      })

      const updatedNewTasksNotifList = {
        ...state.newTasksNotifList,
        content: newTasksContent
      }

      return {
        ...state,
        loading: false,
        newTasksNotifList: updatedNewTasksNotifList
      }
    case READ_NEW_TASK_NOTIF_FAILED:
      return { ...state, loading: false }

    case SHOW_NEW_TASK_NOTIF:
      return { ...state, newTaskNotif: action.payload }
    case CLOSE_NEW_TASK_NOTIF:
      return { ...state, newTaskNotif: null }

    case LAYOUT_LOADING:
      return { ...state, loading: action.payload }

    case CLEAR_STORE:
      return { ...initState }

    default:
      return state
  }
}

export default reducer
