import ax from '../../common/helpers/request'
import history from '../../common/helpers/routingHistory'
import { fetchCategories } from '../createProduct/actions'
import { addUserNotification } from '../home/action'
import { switchLayoutLoading } from '../layout/action'

export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS'
export const CHANGE_SUPPLEMENT = 'CHANGE_SUPPLEMENT'

export const fetchProduct = id => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      await dispatch(fetchCategories())

      const { data } = await ax.get(`/api/products/${id}`)
      dispatch({ type: FETCH_PRODUCT_SUCCESS, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const editProduct = (dto, id) => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      await ax.put(`/api/products/${id}`, dto)
      history.push('/goods/catalog')
      dispatch(addUserNotification('Товар успешно изменен!', 'success'))
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const updateProductPrice = (dto, id, storeId) => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      await ax.put(`/api/products/${id}/price?storeId=${storeId}`, dto)
      history.push('/goods/catalog')
      dispatch(addUserNotification('Товар успешно изменен!', 'success'))
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const getProductPrice = async (id, storeId) => {
  const res = await ax.get(`/api/products/${id}/price?storeId=${storeId}`)

  return res.data
}
