import React from 'react'
import loading from '../../assets/images/loader.svg'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles({
  loader: {
    height: '80px',
    width: '80px'
  },
  loaderContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255,255,255,.7)',
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 9999
  },
  customContainer: {
    width: 530,
    height: 380,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(255,255,255,.7)',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 9999
  }
})

export function Loader({ containerClass }) {
  const classes = useStyles()
  return (
    <div
      className={clsx(classes.loaderContainer, {
        [classes.customContainer]: Boolean(containerClass)
      })}>
      <img src={loading} alt='loading' className={classes.loader} />
    </div>
  )
}
