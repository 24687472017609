import ax from '../../common/helpers/request'
import { addUserNotification } from '../home/action'
import { switchLayoutLoading } from '../layout/action'

export const FETCH_COUNT_SALES = 'FETCH_COUNT_SALES'
export const FETCH_ALL_SALES = 'FETCH_ALL_SALES'

export const fetchCountSales = filters => {
  const params = new URLSearchParams(filters)
  const stringParams = params.toString()

  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      const { data } = await ax.get(`/api/sales/overall?${stringParams}`)
      dispatch({ type: FETCH_COUNT_SALES, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const fetchAllSales = (page, size, filters) => {
  const params = new URLSearchParams(filters)
  const stringParams = params.toString()

  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      const { data } = await ax.get(
        `/api/sales/?page=${page}&size=${size}&${stringParams}`
      )
      dispatch({ type: FETCH_ALL_SALES, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}
