import {
  SET_USER_CREDENTIALS,
  LOGIN_LOADING,
  GET_FINGERPRINT,
  ADD_USER_NOTIFICATION,
  DISABLE_USER_NOTIFICATION,
  SET_LOGIN_TIME,
  CLEAR_STORE,
  SET_AUTHORIZED
} from './constants'

const initialState = {
  user: { firstName: '', lastName: '' },
  loginFailure: false,
  phoneConfirmation: {},
  codeVerification: {},
  codeVerificationAttempt: 0,
  customer: {},
  loading: false,
  fingerPrint: '',
  language: 'RU',
  languages: ['RU', 'UZ', 'EN'],
  loadingLanguage: false,
  times: {},
  availableRoutes: [],
  isAuthorized: false
}

const reducer = (state = initialState, action) => {
  const newState = Object.assign({}, state)
  const payload = action.payload
  let notifications
  switch (action.type) {
    case GET_FINGERPRINT:
      newState.fingerPrint = payload
      return newState

    case ADD_USER_NOTIFICATION:
      if (action.payload.type === 'SKIP') {
        return state
      }
      if (state.notifications && state.notifications.length > 0) {
        payload.id = state.notifications[state.notifications.length - 1].id + 1
        notifications = state.notifications.concat(action.payload)
      } else {
        payload.id = 1
        notifications = [action.payload]
      }
      return { ...state, notifications }

    case DISABLE_USER_NOTIFICATION:
      if (state.notifications && state.notifications.length > 0) {
        notifications = state.notifications.filter(
          item => item.key !== payload.key
        )
      }
      return { ...state, notifications }

    case SET_USER_CREDENTIALS:
      return {
        ...state,
        user: action.payload,
        loading: false
      }

    case LOGIN_LOADING:
      newState.loading = payload
      return newState

    case SET_LOGIN_TIME:
      return { ...state, times: action.payload }

    case SET_AUTHORIZED:
      return { ...state, isAuthorized: action.payload }

    case CLEAR_STORE:
      return { ...initialState }

    default:
      return state
  }
}

export default reducer
