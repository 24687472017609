import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'
import blue from '@material-ui/core/colors/blue'
import red from '@material-ui/core/colors/red'
import IconButton from '@material-ui/core/IconButton'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core'
import { disableNotification } from '../../modules/home/action'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

const styles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: red[600]
  },
  info: {
    backgroundColor: blue[600]
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  margin: {
    margin: theme.spacing(1)
  }
}))

let displayed = []

const Notifier = () => {
  const dispatch = useDispatch()
  const { notifications = [] } = useSelector(state => state.user)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const classes = styles()
  const storeDisplayed = id => {
    displayed = [...displayed, id]
  }

  const removeDisplayed = id => {
    displayed = [...displayed.filter(key => id !== key)]
  }

  useEffect(() => {
    // closeSnackbar();
    notifications.forEach(({ key, text, type }) => {
      if (displayed.includes(key)) return
      const Icon = variantIcon[type]
      const content = () => (
        <SnackbarContent
          className={classes[type]}
          message={
            <span id='message-id' className={classes.message}>
              <Icon className={classNames(classes.icon, classes.iconVariant)} />
              {text}
            </span>
          }
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              className={classes.close}
              onClick={() => closeSnackbar(key)}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
        />
      )
      enqueueSnackbar(text, {
        key,
        autoHideDuration: 6000,
        onClose: () => {
          disableNotification(key)
        },
        content,
        onExited: (event, myKey) => {
          dispatch(disableNotification(myKey))
          removeDisplayed(myKey)
        }
      })

      storeDisplayed(key)
    })
    return () => {}
    // eslint-disable-next-line
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

  return null
}

export default Notifier
