import ax from '../../common/helpers/request'
import { addUserNotification } from '../home/action'
import { switchLayoutLoading } from '../layout/action'

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'

export const fetchCatalog = ({ page, size, filter, storeIdList }) => {
  const params = new URLSearchParams(filter)
  const stringParams = params.toString()
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    let api = `/api/products?page=${page}&size=${size}&${stringParams}`
    if (storeIdList || storeIdList === 0) {
      api = api + `&storeIdList=${storeIdList}`
    }
    try {
      const { data } = await ax.get(api)
      dispatch({ type: FETCH_PRODUCTS, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}
