import axios from '../../common/helpers/request'
import { config } from './config'
import {
  getTaskManagerDataPath,
  GET_TASK_MANAGER_DATA_STARTED,
  GET_TASK_MANAGER_DATA_SUCCEEDED,
  GET_USER_STATE_LIST_STARTED,
  GET_USER_STATE_LIST_SUCCEEDED,
  GET_USER_STATE_LIST_FAILED,
  CHANGE_USER_STATE_STARTED,
  CHANGE_USER_STATE_SUCCEEDED,
  CHANGE_USER_STATE_FAILED,
  GET_USER_STATE_STARTED,
  GET_USER_STATE_SUCCEEDED,
  GET_USER_STATE_FAILED,
  SHOW_SUB_STATUS,
  FETCH_NOTIF_NEW_TASKS_STARTED,
  FETCH_NOTIF_NEW_TASKS_SUCCEEDED,
  FETCH_NOTIF_NEW_TASKS_FAILED,
  READ_NEW_TASK_NOTIF_STARTED,
  READ_NEW_TASK_NOTIF_SUCCEEDED,
  READ_NEW_TASK_NOTIF_FAILED,
  SHOW_NEW_TASK_NOTIF,
  CLOSE_NEW_TASK_NOTIF,
  LAYOUT_LOADING
} from './constants'
import { addUserNotification } from '../home/action'
import history from '../../common/helpers/routingHistory'

export const showSubStatus = (status, subStatuses) => {
  return { type: SHOW_SUB_STATUS, status, subStatuses }
}

export const getUserState = () => {
  return async dispatch => {
    try {
      dispatch({ type: GET_USER_STATE_STARTED })
      const response = await axios.get(config.getUserStateUrl())
      dispatch({
        type: GET_USER_STATE_SUCCEEDED,
        payload: response.data
      })
    } catch (error) {
      dispatch({ type: GET_USER_STATE_FAILED, payload: error })
      dispatch(addUserNotification(error))
    }
  }
}

export const changeUserState = dto => {
  return async dispatch => {
    try {
      dispatch({ type: CHANGE_USER_STATE_STARTED })
      const response = await axios.post(config.setUserStateUrl(), dto)
      const redirect = response.data.redirectPage

      if (response.data.message) {
        dispatch(addUserNotification(response.data, 'warning'))
      }
      dispatch({ type: CHANGE_USER_STATE_SUCCEEDED, payload: response.data })

      if (redirect === 'MY_PORTFOLIO') {
        history.push(`/my-portfolio`)
      } else if (redirect === 'MY_TASKS') {
        history.push(`/my-tasks`)
      }
    } catch (error) {
      dispatch({ type: CHANGE_USER_STATE_FAILED, error })
      dispatch(addUserNotification(error))
      dispatch(getUserState())
    }
  }
}

export const getUserStateList = () => {
  return async dispatch => {
    try {
      dispatch({ type: GET_USER_STATE_LIST_STARTED })
      const response = await axios.get(config.getUserStateListUrl())
      dispatch({
        type: GET_USER_STATE_LIST_SUCCEEDED,
        payload: response.data
      })
    } catch (error) {
      dispatch({ type: GET_USER_STATE_LIST_FAILED, payload: error })
      dispatch(addUserNotification(error))
    }
  }
}

export const getTaskManagerData = data => {
  return async dispatch => {
    await dispatch({ type: GET_TASK_MANAGER_DATA_STARTED })
    try {
      const url = data
        ? getTaskManagerDataPath() +
          `?action=${data.action}&week=${data.week}&year=${data.year}`
        : getTaskManagerDataPath()
      const response = await axios({
        url,
        method: 'GET'
      })
      await dispatch({
        type: GET_TASK_MANAGER_DATA_SUCCEEDED,
        payload: response.data
      })
    } catch (e) {}
  }
}

export const fetchNotifNewTasks = (openNotifWindow, rowsPerPage, page) => {
  return async dispatch => {
    dispatch({ type: FETCH_NOTIF_NEW_TASKS_STARTED })
    try {
      const response = await axios.post(
        config.notifNewTasksUrl(rowsPerPage, page),
        {}
      )
      dispatch({
        type: FETCH_NOTIF_NEW_TASKS_SUCCEEDED,
        payload: response.data
      })
      openNotifWindow()
    } catch (error) {
      dispatch({ type: FETCH_NOTIF_NEW_TASKS_FAILED })
      dispatch(addUserNotification(error))
    }
  }
}

export const readNewTaskNotif = id => {
  return async dispatch => {
    dispatch({ type: READ_NEW_TASK_NOTIF_STARTED })
    try {
      await axios.get(config.readNewMessageNotifUrl(id))
      dispatch({ type: READ_NEW_TASK_NOTIF_SUCCEEDED, payload: id })
    } catch (error) {
      dispatch({ type: READ_NEW_TASK_NOTIF_FAILED })
      dispatch(addUserNotification(error))
    }
  }
}

export const showNewTaskNotif = payload => {
  return { type: SHOW_NEW_TASK_NOTIF, payload }
}

export const closeNewTaskNotif = () => {
  return { type: CLOSE_NEW_TASK_NOTIF }
}

export const switchLayoutLoading = payload => {
  return { type: LAYOUT_LOADING, payload }
}
