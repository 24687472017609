import { CLEAR_STORE } from '../home/constants'
import { FETCH_TRANSFERS, SAVE_TRANSFER_FORM_DATA } from './actions'

const initialState = {
  transfers: { content: [], totalElements: 0 },
  transferFormData: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSFERS:
      return { ...state, transfers: action.payload }

    case SAVE_TRANSFER_FORM_DATA:
      return { ...state, transferFormData: action.payload }

    case CLEAR_STORE:
      return { ...initialState }

    default:
      return state
  }
}

export default reducer
