const loginPath = '/auth'

const config = {
  login: () => {
    return `${loginPath}/signin`
  },
  getUser: () => {
    return `${loginPath}/me`
  },
  logout: () => {
    return `${loginPath}/signout`
  },
  refreshUrl: () => {
    return `${loginPath}/auth/refresh`
  }
}

export default config
