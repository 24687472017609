import React from 'react'
import Button from '@material-ui/core/Button'
import errorImg from '../assets/images/error.jpg'

class GlobalErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
          <img src={errorImg} alt='Error' />
          <h1 style={{ fontSize: '5.5rem' }}>😓</h1>
          <h1 style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>
            Упс! Что-то пошло не так.{`\n`}Попробуйте еще раз.
          </h1>
          <Button
            style={{ marginTop: 30 }}
            color='primary'
            variant='contained'
            onClick={() => window.location.reload()}>
            Перезагрузить страницу
          </Button>
        </div>
      )
    }

    return <>{this.props.children}</>
  }
}

export default GlobalErrorBoundary
