import ax from '../../common/helpers/request'
import { addUserNotification } from '../home/action'
import { switchLayoutLoading } from '../layout/action'

export const FETCH_FINANCE_LIST = 'FETCH_FINANCE_LIST'

export const fetchFinanceList = (page, size, filter) => {
  const params = new URLSearchParams(filter)
  const stringParams = params.toString()
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    let api = `/api/cash/flow?pageNumber=${page}&pageSize=${size}&${stringParams}`
    try {
      const { data } = await ax.get(api)
      dispatch({ type: FETCH_FINANCE_LIST, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const FETCH_ACCOUNT_INFO = 'FETCH_ACCOUNT_INFO'

export const fetchAccountInfo = () => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    let api = `/api/cash/flow/wallet`
    try {
      const { data } = await ax.get(api)
      dispatch({ type: FETCH_ACCOUNT_INFO, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const SET_WALLET_TYPE_LIST = 'SET_WALLET_TYPE_LIST'

export const fetchWalletTypeList = () => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    let api = `/api/refs?type=WALLET_TYPE`
    try {
      const { data } = await ax.get(api)
      dispatch({ type: SET_WALLET_TYPE_LIST, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const SET_PAYMENT_ARTICLE_LIST = 'SET_PAYMENT_ARTICLE_LIST'

export const fetchPaymentArticleList = () => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    let api = `/api/refs?type=PAYMENT_ARTICLE`
    try {
      const { data } = await ax.get(api)
      dispatch({ type: SET_PAYMENT_ARTICLE_LIST, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const SET_CURRENCY_LIST = 'SET_CURRENCY_LIST'

export const fetchCurrencyList = () => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    let api = `/api/refs?type=CURRENCY`
    try {
      const { data } = await ax.get(api)
      dispatch({ type: SET_CURRENCY_LIST, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}
