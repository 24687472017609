import { CLEAR_STORE } from '../home/constants'
import { FETCH_ORDERS } from './actions'

const initialState = {
  orders: { content: [], totalElements: 0 }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDERS:
      return { ...state, orders: action.payload }

    case CLEAR_STORE:
      return { ...initialState }

    default:
      return state
  }
}

export default reducer
