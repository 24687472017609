export const DEFAULT_HEADERS = {
  'Content-Type': 'application/json'
  // accept: "*/*"
}
export const FORM_DATA_HEADER = {
  'Content-Type': 'multipart/form-data'
}

// export const developmentOrigin = "https://192.168.102.6:9191"
// export const developmentOrigin = "https://10.10.15.41:9191"
// export const developmentOrigin = 'http://87.255.194.116:8088'
//export const developmentOrigin = 'https://assistant.org.kz'
// export const developmentOrigin = 'https://zerdetoys.assistant.org.kz'
// export const developmentOrigin = 'https://dev.assistant.org.kz'
export const developmentOrigin =
  process.env.NODE_ENV === 'development'
    ? '/api'
    : process.env.REACT_APP_API_URL
console.log('=============================')
console.log(developmentOrigin)
console.log(process.env.NODE_ENV)

export const DEFAULT_ROWS_PER_PAGE = 25
export const DEFAULT_PAGE = 0

export const UPLOAD_FILE_SUCCESS_MESSAGE = 'Файл успешно загружен!'

export const PAGINATION_TEXT = 'Количество строк на странице'

export const MAX_NUM_FIELDS_MESSAGE = {
  response: { data: { message: 'Максимальное количество полей в таблице - 6' } }
}

export const MAX_NUM_FIELDS_IN_FORM_MESSAGE = {
  response: { data: { message: 'Максимальное количество полей в форме - 5' } }
}

export const getMaxNumFieldsMessage = (num = 6, component = '') => ({
  response: {
    data: { message: `Максимальное количество полей ${component} - ${num}` }
  }
})

export const API = '/api/v1'

export const ACCESS_TIME = 5
export const REFRESH_TIME = 30

export const allowedPaths = [
  '/contract',
  '/my-tasks',
  '/companies-tasks',
  '/card-npa',
  '/companies-tasks/contracts',
  '/contract/member/',
  '/contract/pledge'
]

export const inlineStylesBtnHeader = {
  textTransform: 'none',
  backgroundColor: '#fff',
  fontSize: 12,
  borderRadius: 10,
  padding: '9px 15px',
  margin: '0 10px',
  color: '#00AAFF',
  fontWeight: 600
}

export const appColors = {
  primary: '#D4EA4F',
  secondary: '#3B52CA',
  dark: '#1D2023',
  gray1: '#677480',
  gray2: '#A7AFB6',
  gray3: '#ACB5BC',
  divider: '#E1E4E8',
  light: '#ECEFF1',
  background: '#F7F8F9',
  white: '#FFFFFF',
  lightblue: '#3B52CA', // 10% in figma
  green: '#359F24',
  red: '#E7443D'
}

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  CASHIER: 'CASHIER',
  CLIENT: 'CLIENT',
  PICKER: 'PICKER'
}
