import { CLEAR_STORE } from '../home/constants'
import { FETCH_SALE, FETCH_SALE_CHECK, FETCH_TRANSACTION_ID } from './actions'

const initialState = {
  sales: {
    client: {},
    paymentList: [],
    saleItemList: [],
    store: {},
    totalAmount: 0
  },
  salesInfo: {},
  transactionId: 0
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SALE:
      return { ...state, sales: action.payload }

    case FETCH_SALE_CHECK:
      return { ...state, salesInfo: action.payload }

    case FETCH_TRANSACTION_ID:
      return { ...state, transactionId: action.payload }

    case CLEAR_STORE:
      return { ...initialState }

    default:
      return state
  }
}

export default reducer
