const userStateListApi = '/api/v1/user/state/list'
const setUserStateApi = '/api/v1/user/state/set'
const userStateApi = '/api/v1/user/state'

export const config = {
  getUserStateUrl: () => {
    return userStateApi
  },
  getUserStateListUrl: () => {
    return userStateListApi
  },
  setUserStateUrl: () => {
    return setUserStateApi
  },
  notifNewTasksUrl: (size, page) =>
    `/api/v1/notifications/list?page=${page}&size=${size}`,
  readNewMessageNotifUrl: id => '/api/v1/notifications/read?id=' + id
}
