import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import Routing from './router'
import history from './common/helpers/routingHistory'
import './index.css'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { SnackbarProvider } from 'notistack'
import GlobalErrorBoundary from './common/components/GlobalErrorBoundary'

import { customAlphabet } from 'nanoid'
export const nanoidNum = customAlphabet('1234567890', 10)

window.addEventListener('load', async () => {
  if ('serviceWorker' in navigator) {
    try {
      await navigator.serviceWorker.register('/sw.js')
    } catch (e) {
      console.log(e, 'Service worker register fail')
    }
  }
})

function App() {
  return (
    <>
      <GlobalErrorBoundary>
        <Provider store={store}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}>
            <Router history={history}>
              <Routing />
            </Router>
          </SnackbarProvider>
        </Provider>
      </GlobalErrorBoundary>
    </>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
