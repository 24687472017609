import fp from 'fingerprintjs2'

const options = {
  excludes: {
    userAgent: false,
    webdriver: true,
    language: true,
    colorDepth: true,
    deviceMemory: false,
    hardwareConcurrency: true,
    screenResolution: true,
    availableScreenResolution: true,
    timezoneOffset: true,
    timezone: true,
    sessionStorage: true,
    localStorage: true,
    indexedDb: true,
    addBehavior: true,
    openDatabase: true,
    cpuClass: true,
    platform: true,
    plugins: true,
    canvas: true,
    webgl: true,
    webglVendorAndRenderer: false,
    adBlock: true,
    hasLiedLanguages: true,
    hasLiedResolution: true,
    hasLiedOs: true,
    hasLiedBrowser: true,
    touchSupport: true,
    fonts: true,
    audio: true,
    pixelRatio: true,
    doNotTrack: true,
    fontsFlash: true,
    enumerateDevices: true
  }
}

const hashCode = (array, username) => {
  const string = array.map(item => item.value).join('') + username.toLowerCase()
  const stringHashed = string.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0)
    return a
  }, 0)
  return String(stringHashed)
}

export const getFingerprint = username =>
  // eslint-disable-next-line no-undef
  new Promise(resolve => {
    fp.get(options, components => {
      resolve(hashCode(components, username))
    })
  })
