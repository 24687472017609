import { Button } from '@material-ui/core'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { sidebar } from '../..'
import { ReactComponent as SidebarCloseIcon } from '../../../../common/assets/images/close_button.svg'
import { ReactComponent as LeftArrowIcon } from '../../../../common/assets/images/left_arrow_icon.svg'
import { ReactComponent as WarningIcon } from '../../../../common/assets/images/warning_icon.svg'
import { appColors } from '../../../../common/constants'
import { logout } from '../../../home/action'
import useStyles from './styles'

// const stores = [
//   { id: 0, title: "Store woodaccs" },
//   { id: 1, title: "Store 2" },
//   { id: 2, title: "Store 3" },
//   { id: 3, title: "All store" },
// ];

export default function AccountSidebar({ sidebarState, setSidebarState }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const firstName = useSelector(state => state.user?.user?.firstName)
  const lastName = useSelector(state => state.user?.user?.lastName)

  return (
    <>
      {/* account content */}
      {sidebarState === sidebar.account && (
        <div className={classes.accountSidebar}>
          <div className={classes.accountSidebarHeader}>
            <h5 className={classes.sidebarTitle}>Аккаунт</h5>
            <SidebarCloseIcon
              onClick={() => setSidebarState(null)}
              className={classes.sidebarCloseIcon}
            />
          </div>

          <div className={classes.avatarBlock}>
            <div className={classes.avatar}>
              {firstName?.[0]}
              {lastName?.[0]}
            </div>
            <div className={classes.avatarDescription}>
              <div className={classes.avatarName}>
                {firstName} {lastName?.[0]}.
              </div>
              <div className={classes.avatarStoreName}>Store woodaccs</div>
            </div>
          </div>

          {/* <Button
            fullWidth
            variant="text"
            className={classes.accountSidebarMenuButton}
            onClick={() => setSidebarState(sidebar.changeStore)}
          >
            <ChangeStoreIcon style={{ marginRight: 10 }} />
            Сменить магазин
          </Button> */}

          <Button
            fullWidth
            variant='text'
            className={clsx(
              classes.accountSidebarMenuButton,
              classes.accountSidebarExitButton
            )}
            onClick={() => setSidebarState(sidebar.logout)}>
            Выйти из аккаунта
          </Button>
        </div>
      )}

      {/* change store content */}
      {/* {sidebarState === sidebar.changeStore && (
        <div className={classes.accountSidebar}>
          <div className={classes.accountSidebarHeader} style={{ justifyContent: "flex-start" }}>
            <LeftArrowIcon
              onClick={() => setSidebarState(sidebar.account)}
              className={classes.sidebarCloseIcon}
              style={{ marginRight: 12 }}
            />
            <h5 className={classes.sidebarTitle}>Сменить магазин</h5>
          </div>

          {stores.map((store) => {
            return (
              <Button
                key={store.id}
                fullWidth
                variant="text"
                className={classes.accountSidebarMenuButton}
                style={{ margin: "0 0 12px 0" }}
              >
                {store.title}
                {store.id === 0 && <CheckIcon style={{ marginLeft: 11 }} />}
              </Button>
            );
          })}
        </div>
      )} */}

      {/* logout account content */}
      {sidebarState === sidebar.logout && (
        <div
          className={classes.accountSidebar}
          style={{ justifyContent: 'space-between' }}>
          <div
            className={classes.accountSidebarHeader}
            style={{ justifyContent: 'flex-start' }}>
            <LeftArrowIcon
              onClick={() => setSidebarState(sidebar.account)}
              className={classes.sidebarCloseIcon}
              style={{ marginRight: 12 }}
            />
            <h5 className={classes.sidebarTitle}>Выйти из аккаунта</h5>
          </div>

          <div className={classes.warningLogoutBlock}>
            <WarningIcon />
            <span className={classes.warningLogoutMessage}>
              Вы уверены что хотите выйти <br /> из аккаунта?
            </span>
          </div>

          <div>
            <Button
              fullWidth
              variant='text'
              className={classes.accountSidebarMenuButton}
              style={{ margin: '0 0 12px 0', color: appColors.red }}
              onClick={() => dispatch(logout())}>
              Да, выйти
            </Button>
            <Button
              fullWidth
              variant='text'
              className={classes.accountSidebarMenuButton}
              style={{ margin: '0 0 12px 0' }}
              onClick={() => setSidebarState(sidebar.account)}>
              Отмена
            </Button>
          </div>
        </div>
      )}
    </>
  )
}
