import ax from '../../common/helpers/request'
import history from '../../common/helpers/routingHistory'
import { addUserNotification } from '../home/action'
import { switchLayoutLoading } from '../layout/action'

export const SET_PRODUCTS = 'SET_PRODUCTS'

export const createTransfer = dto => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      await ax.post('/api/order/transfers', dto)
      dispatch(addUserNotification('Новый трансфер успешно создан!', 'success'))
      history.push('/goods/transfers')
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const fetchTransferCatalog = ({
  page,
  size,
  fromStoreId,
  toStoreId,
  filter
}) => {
  const params = new URLSearchParams(filter)
  const stringParams = params.toString()
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    let api = `/api/order/transfers/product?page=${page}&size=${size}&fromStoreId=${fromStoreId}&toStoreId=${toStoreId}&${stringParams}`
    try {
      const { data } = await ax.get(api)
      dispatch({ type: SET_PRODUCTS, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}
