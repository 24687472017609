import ax from '../../common/helpers/request'
import history from '../../common/helpers/routingHistory'
import { addUserNotification } from '../home/action'
import { switchLayoutLoading } from '../layout/action'

export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS'
export const FETCH_PROVIDERS_SUCCESS = 'FETCH_PROVIDERS_SUCCESS'
export const FETCH_STORES_SUCCESS = 'FETCH_STORES_SUCCESS'
export const SET_CHECKED_CATEGORIES = 'SET_CHECKED_CATEGORIES'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const CHANGE_STATE_FIELDS = 'CHANGE_STATE_FIELDS'
export const SET_BAR_CODE = 'SET_BAR_CODE'
export const SET_BAR_CODES = 'SET_BAR_CODES'
export const SET_VENDOR_CODE = 'SET_VENDOR_CODE'
export const SET_NEW_SUPPLEMENT = 'SET_NEW_SUPPLEMENT'
export const RESET_CREATE_PRODUCT_STATE = 'RESET_CREATE_PRODUCT_STATE'

export const fetchCategories = () => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      const { data } = await ax.get('/api/product/category?page=0&size=500')
      dispatch({ type: FETCH_CATEGORY_SUCCESS, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const fetchProvidersAndStores = () => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))

    try {
      Promise.all([
        ax.get('/api/providers?page=0&size=2000'),
        ax.get('/api/store?page=0&size=2000')
      ]).then(res => {
        const [providersRes, storesRes] = res

        dispatch({ type: FETCH_PROVIDERS_SUCCESS, payload: providersRes.data })
        dispatch({ type: FETCH_STORES_SUCCESS, payload: storesRes.data })
      })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const fetchProviders = () => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      const { data } = await ax.get('/api/providers?page=0&size=2000')
      dispatch({ type: FETCH_PROVIDERS_SUCCESS, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const fetchStores = () => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      const { data } = await ax.get('/api/store?page=0&size=2000')
      dispatch({ type: FETCH_STORES_SUCCESS, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const uploadImage = file => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    const formData = new FormData()
    formData.append('file', file)
    try {
      const { data } = await ax.post('/upload?type=IMAGE', formData)
      dispatch({ type: UPLOAD_IMAGE_SUCCESS, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const genBarCode = (id, index) => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))

    try {
      const { data } = await ax.get(
        `/api/products/generate/bar-code?categoryId=${id}`
      )
      dispatch({ type: SET_BAR_CODE, payload: { barcode: data, index } })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const addBarCode = id => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))

    try {
      const { data } = await ax.get(
        `/api/products/generate/bar-code?categoryId=${id}`
      )
      dispatch({ type: SET_BAR_CODES, payload: String(data) })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const genVendorCode = id => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      const { data } = await ax.get(
        `/api/products/generate/vendor-code?categoryId=${id}`
      )
      dispatch({ type: SET_VENDOR_CODE, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const createProduct = dto => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      await ax.post('/api/products', dto)
      dispatch(addUserNotification('Товар успешно создан!', 'success'))
      history.push('/goods/catalog')
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const createStore = (store, onClose) => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      await ax.post('/api/store', store)
      dispatch(addUserNotification('Магазин успешно создан!', 'success'))
      dispatch(fetchStores())
      onClose()
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const createProvider = (provider, onClose) => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      await ax.post('/api/providers', provider)
      dispatch(addUserNotification('Поставщик успешно создан!', 'success'))
      dispatch(fetchProviders())
      onClose()
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}
