import { lazy, Suspense, useEffect } from 'react'
import {
  Route,
  Router as BrowserRouter,
  Switch,
  withRouter
} from 'react-router-dom'

import Notifier from '../common/components/notifications'
import history from '../common/helpers/routingHistory'
import Layout from '../modules/layout'
import routes from './config'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { checkUserRole } from '../common/helpers/checkUserRole'
import { getCurrentUser, showNotification } from '../modules/home/action'

const AuthorizedRoute = ({
  component: Component,
  allowedRoles = [],
  ...rest
}) => {
  const isAuthorized = useSelector(state => state.user.isAuthorized)

  if (isAuthorized === false) {
    return <Redirect to={'/login'} />
  }

  return (
    <Route
      {...rest}
      render={props => {
        return <Component {...props} />
      }}
    />
  )
}

const Router = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    const token = localStorage.getItem('token')

    if (token) {
      dispatch(getCurrentUser(token))
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const foundedRoute = routes.find(route => {
      if (typeof route.path !== 'string') {
        return false
      }

      return route.path.replace(':id', '').includes(location.pathname)
    })

    if (foundedRoute?.allowedRoles.length > 0) {
      if (checkUserRole(foundedRoute.allowedRoles) === false) {
        dispatch(
          showNotification('Извините, у вас нет доступа к данной странице')
        )

        history.push('/goods/catalog')
      }
    }

    // eslint-disable-next-line
  }, [location.pathname])

  return (
    <>
      <BrowserRouter history={history}>
        <Suspense fallback={null}>
          <Notifier />
          <Switch>
            <Route
              exact
              path={['/login', '/']}
              component={lazy(() => import(`../modules/home`))}
            />
            <Layout>
              {routes.map((routeItem, key) => {
                return (
                  <AuthorizedRoute
                    allowedRoles={routeItem.allowedRoles}
                    key={key}
                    path={routeItem.path}
                    exact={routeItem.exact}
                    component={lazy(() =>
                      import(`../modules/${routeItem.component}`)
                    )}
                  />
                )
              })}
            </Layout>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </>
  )
}

export default withRouter(Router)
