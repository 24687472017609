import ax from '../../common/helpers/request'
import history from '../../common/helpers/routingHistory'
import { addUserNotification } from '../home/action'
import { switchLayoutLoading } from '../layout/action'

export const FETCH_TRANSFERS = 'FETCH_TRANSFERS'
export const SAVE_TRANSFER_FORM_DATA = 'SAVE_TRANSFER_FORM_DATA'

export const fetchTransfers = (page, size, filters) => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      const { data } = await ax.get(
        `/api/order/transfers?page=${page}&size=${size}`
      )

      dispatch({ type: FETCH_TRANSFERS, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const createTransfer = dto => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      await ax.post('/api/order/transfers', dto)
      dispatch(addUserNotification('Новый трансфер успешно создан!', 'success'))
      history.push('/goods/transfers')
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}
