import { CLEAR_STORE } from '../home/constants'
import { FETCH_ALL_SALES, FETCH_COUNT_SALES } from './actions'

const initialState = {
  countSales: 0,
  allSales: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COUNT_SALES:
      return { ...state, countSales: action.payload.count }

    case FETCH_ALL_SALES:
      return { ...state, allSales: action.payload }

    case CLEAR_STORE:
      return { ...initialState }

    default:
      return state
  }
}

export default reducer
