import { CLEAR_STORE } from '../home/constants'
import {
  FETCH_ACCOUNT_INFO,
  FETCH_FINANCE_LIST,
  SET_CURRENCY_LIST,
  SET_PAYMENT_ARTICLE_LIST,
  SET_WALLET_TYPE_LIST
} from './actions'

const initialState = {
  financeList: { content: [], totalElements: 0 },
  accountInfo: [],
  walletTypeList: [],
  paymentArticleList: [],
  currencyList: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FINANCE_LIST:
      return { ...state, financeList: action.payload }
    case FETCH_ACCOUNT_INFO:
      return { ...state, accountInfo: action.payload }
    case SET_WALLET_TYPE_LIST:
      return { ...state, walletTypeList: action.payload }
    case SET_PAYMENT_ARTICLE_LIST:
      return { ...state, paymentArticleList: action.payload }
    case SET_CURRENCY_LIST:
      return { ...state, currencyList: action.payload }
    case CLEAR_STORE:
      return { ...initialState }

    default:
      return state
  }
}

export default reducer
