import React from 'react'
import { Button } from '@material-ui/core'

export function CustomButton({
  variant,
  children,
  onSubmit,
  onClick,
  className,
  startIcon,
  type,
  disabled,
  visible = true,
  ...props
}) {
  if (visible === false) {
    return <></>
  }

  return (
    <Button
      disabled={disabled}
      type={type ? type : null}
      startIcon={startIcon ? startIcon : null}
      className={className}
      onClick={onClick}
      onSubmit={onSubmit}
      variant={variant ? variant : 'outlined'}
      {...props}>
      {children}
    </Button>
  )
}
