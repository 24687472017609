import {
  CHANGE_SUPPLEMENT,
  FETCH_PRODUCT_SUCCESS
} from '../editProduct/actions'
import { CLEAR_STORE } from '../home/constants'
import {
  CHANGE_STATE_FIELDS,
  FETCH_CATEGORY_SUCCESS,
  FETCH_PROVIDERS_SUCCESS,
  FETCH_STORES_SUCCESS,
  RESET_CREATE_PRODUCT_STATE,
  SET_BAR_CODE,
  SET_BAR_CODES,
  SET_CHECKED_CATEGORIES,
  SET_NEW_SUPPLEMENT,
  SET_VENDOR_CODE,
  UPLOAD_IMAGE_SUCCESS
} from './actions'

const initialState = {
  categories: { content: [] },
  providers: [],
  stores: [],
  checkedCategories: {},
  uploadImages: [],
  stateFields: {
    name: '',
    vendorCode: '',
    barcodes: [''],

    height: '',
    length: '',
    quantity: '',
    width: '',
    criticalBalance: '',

    description: ''
  },

  supplements: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_SUCCESS:
      return { ...state, categories: action.payload }

    case FETCH_PROVIDERS_SUCCESS:
      return { ...state, providers: action.payload.content }

    case FETCH_STORES_SUCCESS:
      return { ...state, stores: action.payload.content }

    case SET_CHECKED_CATEGORIES:
      return {
        ...state,
        checkedCategories: { ...action.payload }
      }

    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadImages: [...state?.uploadImages, { ...action.payload }]
      }

    case CHANGE_STATE_FIELDS:
      return {
        ...state,
        stateFields: { ...state.stateFields, ...action.payload }
      }

    case SET_BAR_CODE:
      return {
        ...state,
        stateFields: {
          ...state.stateFields,
          barcodes: [
            ...state.stateFields.barcodes.map((barcode, index) => {
              if (index === action.payload.index) {
                return action.payload.barcode
              }
              return barcode
            })
          ]
        }
      }

    case SET_BAR_CODES:
      return {
        ...state,
        stateFields: {
          ...state.stateFields,
          barcodes: [...state.stateFields.barcodes, action.payload]
        }
      }

    case SET_VENDOR_CODE:
      return {
        ...state,
        stateFields: { ...state.stateFields, vendorCode: action.payload }
      }

    case SET_NEW_SUPPLEMENT:
      return { ...state, supplements: [...state.supplements, action.payload] }

    case RESET_CREATE_PRODUCT_STATE:
      return initialState

    case FETCH_PRODUCT_SUCCESS:
      const {
        productSupplySet = [],
        imageSet = [],
        categorySet = [],
        boxSize,
        ...restProduct
      } = action.payload

      const newCheckedCategories = {}

      categorySet.forEach(category => {
        newCheckedCategories[category.id] = { ...category, checked: true }
      })
      const newState = {
        ...state,
        supplements: productSupplySet,
        uploadImages: imageSet ? imageSet?.map(obj => obj.fileInfo) : [],
        checkedCategories: newCheckedCategories,
        stateFields: { ...restProduct, ...boxSize }
      }

      return newState

    case CHANGE_SUPPLEMENT:
      return {
        ...state,
        supplements: state.supplements.map(s =>
          s.id === action.payload.id ? action.payload : s
        )
      }

    case CLEAR_STORE:
      return { ...initialState }

    default:
      return state
  }
}

export default reducer
