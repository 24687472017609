import clsx from 'clsx'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { sidebar } from '../..'
import { ReactComponent as GoodsIcon } from '../../../../common/assets/images/goods_icon.svg'
import { ReactComponent as SalesIcon } from '../../../../common/assets/images/sales.svg'
import { ReactComponent as MenuLeftArrowIcon } from '../../../../common/assets/images/menu_left_arrow_icon.svg'
import { ReactComponent as WrapMenuIcon } from '../../../../common/assets/images/wrap_menu_icon.svg'
import { ReactComponent as ExpandMenuIcon } from '../../../../common/assets/images/expand_menu_icon.svg'
import { ReactComponent as ClientsIcon } from '../../../../common/assets/images/clients_icon.svg'
import { ReactComponent as MarketingIcon } from '../../../../common/assets/images/marketing_icon.svg'
import { ReactComponent as FinancesIcon } from '../../../../common/assets/images/finances-icon.svg'
import useStyles from './styles'
import { checkUserRole } from '../../../../common/helpers/checkUserRole'
import { USER_ROLES } from '../../../../common/constants'
import { CustomButton } from '../../../../common/components/button'

export default function MenuSidebar({ setSidebarState }) {
  const history = useHistory()
  const { pathname } = useLocation()
  const [wrappedMenu, setWrappedMenu] = useState(false)

  const classes = useStyles({ wrappedMenu })

  const firstName = useSelector(state => state.user?.user?.firstName) || 'N'
  const lastName = useSelector(state => state.user?.user?.lastName) || 'N'

  const iconMenuClickHandler = path => {
    setWrappedMenu(false)
    history.push(path)
  }

  const iconSalesMenuClickHandler = sumMenu => {
    setWrappedMenu(false)
    history.push(`/sales/${sumMenu}`)
  }

  return (
    <>
      {!wrappedMenu && (
        <div className={classes.menuSidebar}>
          <div className={classes.accountSidebarHeader}>
            <h5 className={classes.sidebarTitle}>Ассистент</h5>
            <WrapMenuIcon
              onClick={() => setWrappedMenu(true)}
              className={classes.wrapMenuIcon}
            />
          </div>

          {pathname.includes('/goods') && (
            <div>
              <CustomButton
                centerRipple
                fullWidth
                variant='text'
                className={classes.accountSidebarMenuButton}
                onClick={() => setWrappedMenu(true)}>
                <MenuLeftArrowIcon style={{ marginRight: 'auto' }} />
                <GoodsIcon style={{ marginRight: 10 }} />
                Товары
              </CustomButton>
              <div className={classes.subMenuWrapper}>
                <CustomButton
                  visible={checkUserRole([
                    USER_ROLES.ADMIN,
                    USER_ROLES.CASHIER,
                    USER_ROLES.PICKER
                  ])}
                  className={clsx(classes.subMenuItem, {
                    [classes.activeSubMenuItem]: pathname.includes('catalog')
                  })}
                  fullWidth={false}
                  variant='text'
                  onClick={() => history.push('/goods/catalog')}>
                  Каталог
                </CustomButton>

                <CustomButton
                  visible={checkUserRole([
                    USER_ROLES.ADMIN,
                    USER_ROLES.CASHIER,
                    USER_ROLES.PICKER
                  ])}
                  className={clsx(classes.subMenuItem, {
                    [classes.activeSubMenuItem]: pathname.includes('imports')
                  })}
                  fullWidth={false}
                  variant='text'
                  onClick={() => history.push('/goods/imports')}>
                  Импорт
                </CustomButton>

                <CustomButton
                  visible={checkUserRole([
                    USER_ROLES.ADMIN,
                    USER_ROLES.CASHIER,
                    USER_ROLES.PICKER
                  ])}
                  className={clsx(classes.subMenuItem, {
                    [classes.activeSubMenuItem]: pathname.includes('transfer')
                  })}
                  fullWidth={false}
                  variant='text'
                  onClick={() => history.push('/goods/transfers')}>
                  Трансфер
                </CustomButton>

                <CustomButton
                  visible={checkUserRole([
                    USER_ROLES.ADMIN,
                    USER_ROLES.CASHIER,
                    USER_ROLES.PICKER
                  ])}
                  className={clsx(classes.subMenuItem, {
                    [classes.activeSubMenuItem]: pathname.includes('providers')
                  })}
                  fullWidth={false}
                  variant='text'
                  onClick={() => history.push('/goods/providers')}>
                  Поставщики
                </CustomButton>
              </div>
            </div>
          )}

          {pathname.includes('/clients') && (
            <div>
              <CustomButton
                visible={checkUserRole([USER_ROLES.ADMIN, USER_ROLES.PICKER])}
                centerRipple
                fullWidth
                variant='text'
                className={classes.accountSidebarMenuButton}
                onClick={() => setWrappedMenu(true)}>
                <MenuLeftArrowIcon style={{ marginRight: 'auto' }} />
                <ClientsIcon style={{ marginRight: 10 }} />
                Клиенты
              </CustomButton>
              <div className={classes.subMenuWrapper}>
                <CustomButton
                  visible={checkUserRole([USER_ROLES.ADMIN, USER_ROLES.PICKER])}
                  className={clsx(classes.subMenuItem, {
                    [classes.activeSubMenuItem]:
                      pathname.includes('all_clients')
                  })}
                  fullWidth={false}
                  variant='text'
                  onClick={() => history.push('/clients/all_clients')}>
                  Все клиенты
                </CustomButton>
              </div>
            </div>
          )}

          {pathname.includes('/sales') && (
            <div>
              <CustomButton
                visible={checkUserRole([
                  USER_ROLES.ADMIN,
                  USER_ROLES.CASHIER,
                  USER_ROLES.PICKER
                ])}
                centerRipple
                fullWidth
                variant='text'
                className={classes.accountSidebarMenuButton}
                onClick={() => setWrappedMenu(true)}>
                <MenuLeftArrowIcon style={{ marginRight: 'auto' }} />
                <SalesIcon style={{ marginRight: 10 }} />
                Продажи
              </CustomButton>
              <div className={classes.subMenuWrapper}>
                <CustomButton
                  visible={checkUserRole([
                    USER_ROLES.ADMIN,
                    USER_ROLES.CASHIER,
                    USER_ROLES.PICKER
                  ])}
                  className={clsx(classes.subMenuItem, {
                    [classes.activeSubMenuItem]:
                      pathname.includes('cash_register')
                  })}
                  fullWidth={false}
                  variant='text'
                  onClick={() => history.push('/sales/cash_register')}>
                  Касса
                </CustomButton>
                <CustomButton
                  visible={checkUserRole([
                    USER_ROLES.ADMIN,
                    USER_ROLES.CASHIER,
                    USER_ROLES.PICKER
                  ])}
                  className={clsx(classes.subMenuItem, {
                    [classes.activeSubMenuItem]: pathname.includes('all_sales')
                  })}
                  fullWidth={false}
                  variant='text'
                  onClick={() => history.push('/sales/all_sales')}>
                  Продажи
                </CustomButton>
                <CustomButton
                  visible={checkUserRole([USER_ROLES.ADMIN, USER_ROLES.PICKER])}
                  className={clsx(classes.subMenuItem, {
                    [classes.activeSubMenuItem]: pathname.includes('orders')
                  })}
                  fullWidth={false}
                  variant='text'
                  onClick={() => history.push('/sales/orders')}>
                  Заявки
                </CustomButton>
              </div>
            </div>
          )}

          {pathname.includes('/finances') && (
            <div>
              <CustomButton
                visible={checkUserRole([USER_ROLES.ADMIN])}
                centerRipple
                fullWidth
                variant='text'
                className={classes.accountSidebarMenuButton}
                onClick={() => setWrappedMenu(true)}>
                <MenuLeftArrowIcon style={{ marginRight: 'auto' }} />
                <FinancesIcon style={{ marginRight: 10 }} />
                Продажи
              </CustomButton>
              <div className={classes.subMenuWrapper}>
                <CustomButton
                  visible={checkUserRole([USER_ROLES.ADMIN])}
                  className={clsx(classes.subMenuItem, {
                    [classes.activeSubMenuItem]: pathname.includes('finances')
                  })}
                  fullWidth={false}
                  variant='text'
                  onClick={() => history.push('/finances')}>
                  Финансы
                </CustomButton>
              </div>
            </div>
          )}

          {pathname.includes('/marketing') && (
            <div>
              <CustomButton
                visible={checkUserRole([USER_ROLES.ADMIN, USER_ROLES.PICKER])}
                centerRipple
                fullWidth
                variant='text'
                className={classes.accountSidebarMenuButton}
                onClick={() => setWrappedMenu(true)}>
                <MenuLeftArrowIcon style={{ marginRight: 'auto' }} />
                <MarketingIcon style={{ marginRight: 10 }} />
                Маркетинг
              </CustomButton>
              <div className={classes.subMenuWrapper}>
                <CustomButton
                  visible={checkUserRole([USER_ROLES.ADMIN, USER_ROLES.PICKER])}
                  className={clsx(classes.subMenuItem, {
                    [classes.activeSubMenuItem]: pathname.includes('news')
                  })}
                  fullWidth={false}
                  variant='text'
                  onClick={() => history.push('/marketing/news')}>
                  Новости
                </CustomButton>
              </div>
              <div className={classes.subMenuWrapper}>
                <CustomButton
                  visible={checkUserRole([USER_ROLES.ADMIN, USER_ROLES.PICKER])}
                  className={clsx(classes.subMenuItem, {
                    [classes.activeSubMenuItem]: pathname.includes(
                      'registration_orders'
                    )
                  })}
                  fullWidth={false}
                  variant='text'
                  onClick={() =>
                    history.push('/marketing/registration_orders')
                  }>
                  Регистрация
                </CustomButton>
              </div>
            </div>
          )}

          {pathname.includes('/settings') && (
            <div>
              <CustomButton
                visible={checkUserRole([USER_ROLES.ADMIN, USER_ROLES.PICKER])}
                centerRipple
                fullWidth
                variant='text'
                className={classes.accountSidebarMenuButton}
                onClick={() => setWrappedMenu(true)}>
                <MenuLeftArrowIcon style={{ marginRight: 'auto' }} />
                <MarketingIcon style={{ marginRight: 10 }} />
                Настройки
              </CustomButton>
              <div className={classes.subMenuWrapper}>
                <CustomButton
                  visible={checkUserRole([USER_ROLES.ADMIN, USER_ROLES.PICKER])}
                  className={clsx(classes.subMenuItem, {
                    [classes.activeSubMenuItem]: pathname.includes('settings')
                  })}
                  fullWidth={false}
                  variant='text'
                  onClick={() => history.push('/settings/stores')}>
                  Магазины
                </CustomButton>
              </div>
            </div>
          )}

          <div className={classes.avatarWrapper}>
            <div className={classes.avatarBlock}>
              <div
                className={classes.avatar}
                onClick={() => setSidebarState(sidebar.account)}>
                {firstName?.[0]}
                {lastName?.[0]}
              </div>
              <div className={classes.avatarDescription}>
                <div
                  className={classes.avatarName}
                  onClick={() => setSidebarState(sidebar.account)}>
                  {firstName} {lastName?.[0]}.
                </div>
                <div className={classes.avatarStoreName}>Store woodaccs</div>
              </div>
            </div>
          </div>
        </div>
      )}

      {wrappedMenu && (
        <div className={classes.wrapperMenuSidebar}>
          <div
            className={classes.accountSidebarHeader}
            style={{ justifyContent: 'center' }}>
            <h5 className={classes.sidebarTitle}>А</h5>
            <ExpandMenuIcon
              onClick={() => setWrappedMenu(false)}
              className={classes.wrapMenuIcon}
              style={{ margin: '0 -5px 0 10' }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}>
            <CustomButton
              visible={checkUserRole([
                USER_ROLES.ADMIN,
                USER_ROLES.CASHIER,
                USER_ROLES.PICKER
              ])}
              centerRipple
              fullWidth
              variant='text'
              className={classes.accountSidebarMenuButtonWrapped}
              onClick={() => iconMenuClickHandler('/goods/catalog')}>
              <div style={{ width: '50px' }}>
                <GoodsIcon style={{ width: '100%' }} />
              </div>
              Товары
            </CustomButton>
            <CustomButton
              visible={checkUserRole([USER_ROLES.ADMIN, USER_ROLES.PICKER])}
              centerRipple
              fullWidth
              variant='text'
              className={classes.accountSidebarMenuButtonWrapped}
              onClick={() => iconMenuClickHandler('/clients/all_clients')}>
              <div style={{ width: '50px' }}>
                <ClientsIcon style={{ width: '100%' }} />
              </div>
              Клиенты
            </CustomButton>

            <CustomButton
              visible={checkUserRole([USER_ROLES.ADMIN, USER_ROLES.PICKER])}
              centerRipple
              fullWidth
              variant='text'
              className={classes.accountSidebarMenuButtonWrapped}
              onClick={() => iconMenuClickHandler('/marketing/news')}>
              <div style={{ width: '50px' }}>
                <MarketingIcon style={{ width: '100%' }} />
              </div>
              Маркетинг
            </CustomButton>

            <CustomButton
              visible={checkUserRole([
                USER_ROLES.ADMIN,
                USER_ROLES.CASHIER,
                USER_ROLES.PICKER
              ])}
              centerRipple
              fullWidth
              variant='text'
              className={classes.accountSidebarMenuButtonWrapped}
              onClick={() => iconSalesMenuClickHandler('cash_register')}>
              <div style={{ width: '50px' }}>
                <SalesIcon style={{ width: '100%' }} />
              </div>
              Продажи
            </CustomButton>

            <CustomButton
              visible={checkUserRole([USER_ROLES.ADMIN])}
              centerRipple
              fullWidth
              variant='text'
              className={classes.accountSidebarMenuButtonWrapped}
              onClick={() => iconMenuClickHandler('/finances')}>
              <div style={{ width: '50px' }}>
                <FinancesIcon style={{ width: '100%' }} />
              </div>
              Финансы
            </CustomButton>

            <CustomButton
              visible={checkUserRole([USER_ROLES.ADMIN, USER_ROLES.PICKER])}
              centerRipple
              fullWidth
              variant='text'
              className={classes.accountSidebarMenuButtonWrapped}
              onClick={() => iconMenuClickHandler('/settings/stores')}>
              <div style={{ width: '50px' }}>
                <MarketingIcon style={{ width: '100%' }} />
              </div>
              Настройки
            </CustomButton>
          </div>

          <div className={classes.avatarWrapper}>
            <div className={classes.avatarBlock}>
              <div
                className={classes.avatar}
                style={{ margin: 'auto' }}
                onClick={() => setSidebarState(sidebar.account)}>
                {firstName?.[0]}
                {lastName?.[0]}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
