import { createTheme, ThemeProvider } from '@material-ui/core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Loader } from '../../common/components/loader'

import AccountSidebar from './components/accountSidebar'
import MenuSidebar from './components/menuSidebar'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

export const sidebar = {
  account: 'account',
  // changeStore: "changeStore",
  logout: 'logout'
}

const pathsWithoutMenuSidebar = [
  '/create_product',
  '/create_supplement',
  '/create_client',
  '/create_news'
]

const Layout = props => {
  const { pathname } = useLocation()

  const { loading } = useSelector(state => state.userState)

  const [sidebarState, setSidebarState] = useState(null)

  const theme = createTheme({
    palette: {
      type: 'light',

      primary: {
        main: '#4781D8'
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <AccountSidebar
        sidebarState={sidebarState}
        setSidebarState={setSidebarState}
      />

      {loading && <Loader />}

      <div style={{ display: 'flex' }}>
        {!pathsWithoutMenuSidebar.includes(pathname) && (
          <MenuSidebar setSidebarState={setSidebarState} />
        )}

        <main style={{ width: '100%' }}>
          <SimpleBar
            key={pathname}
            style={{ maxHeight: '100vh', padding: '24px 40px' }}>
            {props.children}
          </SimpleBar>
        </main>
      </div>
    </ThemeProvider>
  )
}

export default Layout
