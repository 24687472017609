import {
  Home as HomeIcon,
  Search as SearchIcon,
  People as PeopleIcon,
  Dashboard as DashboardIcon,
  Receipt as ReceiptIcon,
  LocationCity as LocationCityIcon,
  CheckBox as CheckBoxIcon,
  Assessment as AssessmentIcon,
  AccountBox as AccountBoxIcon,
  LibraryBooks as LibraryBooksIcon,
  Settings as SettingsIcon,
  PeopleOutline as PeopleOutlineIcon,
  List as ListIcon
} from '@material-ui/icons'

export const iconsList = {
  DashboardIcon: DashboardIcon,
  HomeIcon: HomeIcon,
  SearchIcon: SearchIcon,
  PeopleIcon: PeopleIcon,
  ReceiptIcon: ReceiptIcon,
  LocationCityIcon: LocationCityIcon,
  CheckBoxIcon: CheckBoxIcon,
  AssessmentIcon: AssessmentIcon,
  LibraryBooksIcon: LibraryBooksIcon,
  AccountBoxIcon: AccountBoxIcon,
  SettingsIcon: SettingsIcon,
  PeopleOutlineIcon: PeopleOutlineIcon,
  ListIcon: ListIcon
}

export const getTaskManagerDataPath = queryParams => {
  return `/api/v1/planner/list`
}

export const SHOW_SUB_STATUS = 'SHOW_SUB_STATUS'

export const GET_USER_STATE_STARTED = 'GET_USER_STATE_STARTED'
export const GET_USER_STATE_SUCCEEDED = 'GET_USER_STATE_SUCCEEDED'
export const GET_USER_STATE_FAILED = 'GET_USER_STATE_FAILED'

export const GET_USER_STATE_LIST_STARTED = 'GET_USER_STATE_LIST_STARTED'
export const GET_USER_STATE_LIST_SUCCEEDED = 'GET_USER_STATE_LIST_SUCCEEDED'
export const GET_USER_STATE_LIST_FAILED = 'GET_USER_STATE_LIST_FAILED'

export const CHANGE_USER_STATE_STARTED = 'CHANGE_USER_STATE_STARTED'
export const CHANGE_USER_STATE_SUCCEEDED = 'CHANGE_USER_STATE_SUCCEEDED'
export const CHANGE_USER_STATE_FAILED = 'CHANGE_USER_STATE_FAILED'

export const SET_NEW_TASKS_NOTIFICATION_INFO = 'SET_NEW_TASKS_NOTIFICATION_INFO'

export const TOGGLE_LOADER = 'TOGGLE_LOADER'
export const GET_TASK_MANAGER_DATA_STARTED = 'GET_TASK_MANAGER_DATA_STARTED'
export const GET_TASK_MANAGER_DATA_SUCCEEDED = 'GET_TASK_MANAGER_DATA_SUCCEEDED'

export const FETCH_NOTIF_NEW_TASKS_STARTED = 'FETCH_NOTIF_NEW_TASKS_STARTED'
export const FETCH_NOTIF_NEW_TASKS_SUCCEEDED = 'FETCH_NOTIF_NEW_TASKS_SUCCEEDED'
export const FETCH_NOTIF_NEW_TASKS_FAILED = 'FETCH_NOTIF_NEW_TASKS_FAILED'

export const READ_NEW_TASK_NOTIF_STARTED = 'READ_NEW_TASK_NOTIF_STARTED'
export const READ_NEW_TASK_NOTIF_SUCCEEDED = 'READ_NEW_TASK_NOTIF_SUCCEEDED'
export const READ_NEW_TASK_NOTIF_FAILED = 'READ_NEW_TASK_NOTIF_FAILED'

export const SHOW_NEW_TASK_NOTIF = 'SHOW_NEW_TASK_NOTIF'
export const CLOSE_NEW_TASK_NOTIF = 'CLOSE_NEW_TASK_NOTIF'

export const USER_STATUS_CODES = ['DINNER', 'WORKING', 'BREAK']

export const LAYOUT_LOADING = 'LAYOUT_LOADING'
