import { makeStyles } from '@material-ui/core/styles'
import { appColors } from '../../../../common/constants'

const useStyles = makeStyles(theme => ({
  menuSidebar: {
    width: ({ wrappedMenu }) => (wrappedMenu ? 100 : 300),
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: '26px 28px',
    backgroundColor: appColors.light,
    '@media print': {
      display: 'none'
    }
  },
  wrapperMenuSidebar: {
    width: 200,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: '26px 0',
    backgroundColor: appColors.light,
    overflow: 'hidden'
  },
  iconWrappedMenu: {
    margin: 'auto',
    cursor: 'pointer'
  },
  activeIconWrappedMenu: {},
  accountSidebarHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 40
  },
  sidebarTitle: {
    fontSize: '20px',
    margin: 0,
    marginLeft: 10
  },
  wrapMenuIcon: {
    marginRight: 15,
    '&:hover': {
      cursor: 'pointer'
    }
  },

  avatarWrapper: {
    margin: 'auto -28px 0',
    padding: '24px 28px 0',
    borderTop: '1px solid #CDD2D6'
  },

  avatarBlock: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    width: 38,
    height: 38,
    borderRadius: '50%',
    backgroundColor: '#4781D8',
    color: appColors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    cursor: 'pointer'
  },
  avatarDescription: {
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between"
  },
  avatarName: {
    fontSize: 14,
    fontWeight: 600,
    cursor: 'pointer'
  },
  avatarStoreName: {
    fontSize: 14,
    color: appColors.gray1
  },
  subMenuWrapper: {
    // display: "flex",
    // flexDirection: "column",
    marginLeft: 55
  },
  subMenuItem: {
    display: 'block',
    marginTop: 5,
    boxSizing: 'border-box',
    textTransform: 'none',
    borderRadius: 8,
    color: appColors.gray1,
    '&:hover': {
      backgroundColor: 'white',
      color: 'black'
    }
  },
  activeSubMenuItem: {
    backgroundColor: 'white',
    color: 'black'
  },
  accountSidebarMenuButton: {
    height: 44,
    marginTop: 20,
    borderRadius: 8,
    fontSize: 14,
    fontWeight: 600,
    color: appColors.gray1,
    textTransform: 'none',
    padding: '0 60px 0 20px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(71, 129, 216, 0.08)'
    }
  },
  accountSidebarMenuButtonWrapped: {
    height: 44,
    marginTop: 20,
    borderRadius: 8,
    marginLeft: '10px',
    fontSize: 14,
    fontWeight: 600,
    color: appColors.gray1,
    display: 'flex',
    gap: '30px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    // padding: '0 60px 0 20px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(71, 129, 216, 0.08)'
    }
  },

  accountSidebarExitButton: {
    marginTop: 'auto',
    color: appColors.red
  },
  warningLogoutBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  warningLogoutMessage: {
    marginTop: 13,
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'center'
  }
}))

export default useStyles
