import ax from '../../common/helpers/request'
import { addUserNotification } from '../home/action'
import { switchLayoutLoading } from '../layout/action'

export const FETCH_NEWS = 'FETCH_NEWS'

export const fetchNews = (page, size, filters) => {
  const params = new URLSearchParams(filters)
  const stringParams = params.toString()

  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      const { data } = await ax.get(
        `/api/publications?page=${page}&size=${size}&${stringParams}`
      )
      dispatch({ type: FETCH_NEWS, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const removeNews = id => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      await ax.delete(`/api/publications/${id}`)
      dispatch(addUserNotification('Операция прошла успешно!', 'success'))
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}
