/* eslint-disable no-undef */
import axios from 'axios'
import { DEFAULT_HEADERS, developmentOrigin } from '../constants/index'

const ax = axios.create({
  baseURL: developmentOrigin
})

ax.defaults.headers.common = DEFAULT_HEADERS

ax.interceptors.request.use(
  async request => {
    const token = localStorage.getItem('token')

    if (token) {
      request.headers['Authorization'] = token
    }

    if (process.env.NODE_ENV === 'production') {
      return request
    }

    request.url = developmentOrigin + request.url
    return request
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default ax
