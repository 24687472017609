import ax from '../../common/helpers/request'
import { addUserNotification } from '../home/action'
import { switchLayoutLoading } from '../layout/action'

export const FETCH_ORDERS = 'FETCH_ORDERS'

export const fetchOrders = (page, size, filters) => {
  return async dispatch => {
    const params = new URLSearchParams(filters)
    const stringParams = params.toString()
    dispatch(switchLayoutLoading(true))
    try {
      const { data } = await ax.get(
        `/api/orders?page=${page}&size=${size}&${stringParams}`
      )
      dispatch({ type: FETCH_ORDERS, payload: data })
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}

export const completeOrder = dto => {
  return async dispatch => {
    dispatch(switchLayoutLoading(true))
    try {
      await ax.post('/api/orders/stage/complete', dto)
      dispatch(addUserNotification('Этап успешно завершен!', 'success'))
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch(switchLayoutLoading(false))
    }
  }
}
