import { combineReducers } from 'redux'
import user from '../modules/home/reducer'
import { USER_LOGOUT_ACTION } from '../modules/home/constants'
import userState from '../modules/layout/reducer'
import createProduct from '../modules/createProduct/reducer'
import catalog from '../modules/catalog/reducer'
import imports from '../modules/imports/reducer'
import providers from '../modules/providers/reducer'
import transfers from '../modules/transfers/reducer'
import clients from '../modules/clients/reducer'
import news from '../modules/news/reducer'
import orders from '../modules/orders/reducer'
import registrationOrders from '../modules/registrationOrders/reducer'
import sales from '../modules/cashRegister/reducer'
import allSalesInfo from '../modules/allSales/reducer'
import finance from '../modules/finances/reducer'
import newTransfer from '../modules/newTransfer/reducer'

const appReducer = combineReducers({
  user,
  userState,
  createProduct,
  catalog,
  finance,
  imports,
  providers,
  transfers,
  news,
  orders,
  registrationOrders,
  clients,
  sales,
  allSalesInfo,
  newTransfer
})

const rootReducer = (state, action) => {
  const newState = Object.assign({}, state)
  // eslint-disable-next-line default-case
  switch (action.type) {
    case USER_LOGOUT_ACTION:
      sessionStorage.removeItem('skd-session-storage')
      delete newState.user.user
      state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
