import {
  LOGIN_LOADING,
  GET_FINGERPRINT,
  SET_USER_CREDENTIALS,
  USER_LOGOUT_ACTION,
  ADD_USER_NOTIFICATION,
  DISABLE_USER_NOTIFICATION,
  DISABLE_ALL_NOTIFICATION,
  SET_LOGIN_TIME,
  SET_AUTHORIZED
} from './constants'
import { getFingerprint } from '../../common/components/fingerprint'
import localConfig from './config'
import axios from '../../common/helpers/request'
import history from '../../common/helpers/routingHistory'
import moment from 'moment'
import { ACCESS_TIME, REFRESH_TIME } from '../../common/constants'

export const addUserNotification = (error, type = 'error') => {
  let errorMessage
  if (type === 'success') {
    errorMessage = error
  } else if (type === 'warning') {
    errorMessage = error
  } else {
    errorMessage =
      error?.response?.data?.message ||
      error?.response?.data?.error ||
      error?.response?.data?.type ||
      'Произошла ошибка!'
    const { errorCode } = error.response?.data || {}
    if (errorCode === 'INVALID_JWT') {
      type = 'SKIP'
    }
  }

  const key = new Date().getTime() + Math.random()
  const payload = { text: errorMessage, type, key }
  return { type: ADD_USER_NOTIFICATION, payload }
}

export const showNotification = (text, type = 'error') => {
  const key = new Date().getTime() + Math.random()

  return {
    type: ADD_USER_NOTIFICATION,
    payload: {
      text,
      type,
      key
    }
  }
}

export const disableNotification = key => {
  const payload = { key }
  return { type: DISABLE_USER_NOTIFICATION, payload }
}

export const disableAllNotification = () => {
  return { type: DISABLE_ALL_NOTIFICATION }
}

export const getFingerPrint = username => {
  return dispatch => {
    getFingerprint(username).then(res => {
      dispatch({
        type: GET_FINGERPRINT,
        payload: res
      })
    })
  }
}

export const getCurrentUser = parsedToken => {
  return async dispatch => {
    try {
      const { data } = await axios({
        url: localConfig.getUser(),
        method: 'GET',
        headers: { Authorization: parsedToken }
      })

      dispatch({ type: SET_USER_CREDENTIALS, payload: data })
      dispatch({ type: SET_AUTHORIZED, payload: true })

      history.push('/goods/catalog')
    } catch (error) {
      dispatch(addUserNotification(error))
    } finally {
      dispatch({ type: LOGIN_LOADING, payload: false })
    }
  }
}

export function login(payload, setWrongAuthData) {
  return async dispatch => {
    dispatch({ type: LOGIN_LOADING, payload: true })
    try {
      const { data } = await axios({
        url: localConfig.login(),
        method: 'POST',
        data: payload
      })

      const { token, refreshToken } = data

      const parsedToken = 'Bearer ' + token

      localStorage.setItem('token', parsedToken)
      localStorage.setItem('refreshToken', refreshToken)

      dispatch(getCurrentUser(parsedToken))
    } catch (error) {
      dispatch({ type: LOGIN_LOADING, payload: false })
      if (error.response?.data.status === 401) {
        setWrongAuthData(true)
      } else {
        dispatch(addUserNotification(error))
      }
    }
  }
}

export const logout = () => {
  return async dispatch => {
    try {
      await axios({
        url: localConfig.logout(),
        method: 'POST',
        data: {}
      })
      dispatch({ type: USER_LOGOUT_ACTION })
      history.push('/login')
    } catch (e) {
      history.push('/login')
      dispatch(addUserNotification(e))
    }
  }
}

export const deleteCurrentUser = () => {
  return { type: USER_LOGOUT_ACTION }
}

export const setNewTimes = () => {
  const loginTime = moment()
  const accessTime = moment(loginTime).add(ACCESS_TIME, 'minute')
  const refreshTime = moment(loginTime).add(REFRESH_TIME, 'minute')
  return {
    type: SET_LOGIN_TIME,
    payload: { loginTime, accessTime, refreshTime }
  }
}
